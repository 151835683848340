
import React from "react";
import Hero from "../Components/Hero/Hero";
import Header from "../Components/Header";
import Footer from "../Components/Footer/Footer";
import Menu from "../Components/Menu";
import About from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Panda from "../Components/Panda/Panda" ;





const Website =()=> {
   
    return (
       
        <div className="App">
            
 
        <Hero/>
        <Menu/>
       
    <About/>
    <Contact/>
    <Panda/>
    
  
  
        </div>
    )
}
export default Website;