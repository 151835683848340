import React, {useState} from "react"
import "./Header.css"
import {BiMenuAltRight} from "react-icons/bi"
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink,Link } from "react-router-dom";
const Header = () => {
    const [menuOpened,setMenuOpened] = useState(false)
    const getMenuStyles = (menuOpened) => {
        if (document.documentElement.clientWidth <= 800)
        {
            return {right: !menuOpened && "-100%"}
        }
    }
    return (
        <section className="h-wrapper" >
<div className="flexCenter innerWidth paddings h-container">
    <Link to="">
<img src="./logo (1).svg" alt="logo" width={210} marginLeft={-10}/>
</Link>
<OutsideClickHandler
onOutsideClick={()=>
    setMenuOpened(false)
}
>
<div className="flexCenter h-menu"
    style={getMenuStyles(menuOpened)}
    >
    <NavLink href="Home">Home </NavLink>
    <NavLink href="Menu">Menu</NavLink>
    <NavLink href="About">About</NavLink>
    <NavLink href="Contact">Contact</NavLink>
    </div>
    </OutsideClickHandler>
    <div className="menu-icon" onClick={()=>setMenuOpened((prev)=>!prev)}>
    <BiMenuAltRight size={30}/>


</div>
</div>

        </section>
    )
}
export default Header;