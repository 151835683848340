import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="f-wrapper">
      <div className="paddings innerWidth flexCenter f-container">
        {/* left side */}
        <div className="flexColStart f-left">
          <img src="./logo (1).svg" alt="" width={200} />
          <span className="secondaryText">
          If you’re hungry, you’re not happy. 
          <br/>I can help with that
          </span>
        </div>

        <div className="flexColStart f-right">
          <span className="primaryText">Contact Us</span>
          <span className="secondaryText">C-50 ,Noida, Sec-65, UP</span>
          <div className="flexCenter f-menu">
            <span>Our Menu</span>
            <span>Services</span>
            <span>Variaties</span>
            <span>About Us</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;