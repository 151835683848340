import React, { useState } from "react";
import "./Menues.css"

const Menues = () => {
  
    return (
      <div className="wrapper flexCenter" style={{ height: "60vh" }}>
        
      </div>
    );
  }
 
    <div className="wrapper">
      <div className="flexColCenter paddings innerWidth properties-container">
 

        <div className="paddings flexCenter properties">
          
        </div>
      </div>
    </div>
  

export default Menues;