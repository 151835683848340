import React from "react"
import "./Hero.css"


const Hero =() => {
    return (
        <section className="hero-wrapper">
<div className="paddings innerWidth flexCenter hero-Container">
<div classname=" flexColStart hero-left">
    <div className="hero-title">
        <div className="orange-circle"/>
        
                <h1>
            The Fastest <br />
            Food Delivery
              <br /> App
              </h1>
            

    </div>
    <br/>
<div className="flexColStart hero-des">
    <span className="secondaryText">Choose your favorite meal from our broad selection of available
        <br/> meals and enjoy a delicious lunch or dinner at home.</span><span className="secondaryText">All our meals are cooked with high-quality ingredients,<br/>
         just-in-time and of course by experienced chefs!</span>
 

</div>
</div>
<div className="button">
    

Order Now

</div>
<div className=" flexCenter hero-right">
<div className="image-container">
    <img src="./Food.jpg" alt=""/>

</div>
</div>
</div>
        </section>
    )
}
export default Hero;