
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Website from "./Pages/Website"
import Layout from "./Components/Layout/Layout";
import Menues from "./Pages/Menues/Menues";



function App() {
  

  return (
    

<BrowserRouter>
<Routes>
  <Route path="/" element={<Layout />}>
<Route path="/" element={<Website />} />
<Route path="/Menues">
                  <Route index element={<Menues />} />
                  <Route path="Menues" element={<Menues />} />
                </Route>

</Route>
</Routes>
</BrowserRouter>

   
  )
}
export default App;