import react from "react"
import{
    Accordion,
    AccordionItem,
    AcoordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from "react-accessible-accordion/dist/fancy-example.css";
import "react-accessible-accordion/dist/fancy-example.css";
import {MdOutlineArrowdropDown} from "react-icons/md"
import "./About.css"

const About = () => {
    return (
<section className="v-wrapper">
    <div className="paddings innerwidth flexCenter v-container" >
<div className="v-left">
    <div className="image-container">
        <img src="./about.jpg" alt=""/>

    </div>

</div>
<div className="flexColStart v-right">
    <span className="orangeText">About us</span>
    <span className="primaryText">WHY CHOOSE US?</span>
    <span className="secondaryText">Best Food In The City</span>
    <span className="secondaryText">There are many variations of passages of Lorem Ipsum available, 
        but the majority have suffered alteration in some form, by injected humour,
         or randomised words which don't look even slightly believable. If you are going 
         to use a passage of Lorem Ipsum, you need to be sure there isn't 
        anything embarrassing hidden in the middle of text. All</span>
    
        <button className="button1">
            Read More
        </button>
  

</div>
    </div>
</section>
    )
}
export default About;