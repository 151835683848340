import React from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "./Menu.css";

const Residencies = () => {
  return (
    <div id="residencies" className="r-wrapper">
      <div className="paddings innerWidth r-container">
        <div className="flexColStart r-head">
          <span className="orangeText">Best Choices</span>
          <span className="primaryText">Popular Dishes</span>
        </div>
        <Swiper >
          <SlideNextButton />
         
           
            
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./paneer.jpg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>250</span>
                </span>
                <span className="primaryText">Panner Butter Masala </span>
                <span className="secondaryText">This Paneer Butter Masala recipe is a rich and creamy dish of paneer (Indian cottage cheese) in a tomato,<br/> butter and cashew sauce that is known here as “makhani gravy.”</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./idli.jpg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>price</span>
                </span>
                <span className="primaryText">Idli Sambhar</span>
                <span className="secondaryText">Home » South Indian » Idli Sambar | Tiffin Sambar




Idli sambar is a popular South Indian breakfast combination where 
fluffy soft idli (savory steamed cake) is served with hot sambar 
(vegetable lentil stew).
It makes a delicious, healthy, comforting, and protein-packed meal. </span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./gujrati.jpeg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>price</span>
                </span>
                <span className="primaryText">Gujrati Thali</span>
                <span className="secondaryText">A Gujarati thali typically comprises of one or two steamed or fried snacks
                 called farsans, a green vegetable, a tuber or a gourd shaak 
                 (shaaks are main courses with vegetables and spices mixed together into a curry or a spicy dry dish),
                  a kathol
                 (braised pulses like beans, chickpea or dry peas), one or more yogurt ...</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./masala.jpg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>price</span>
                </span>
                <span className="primaryText">Masala Dosa</span>
                <span className="secondaryText">masala dosa is a famous snack most popular in South India, these tasty stuffed crepes are 
                enjoyed in North India as well and can be found on menus throughout the world.
Masala dosa are crispy, soft, savory and healthy crepes made with a rice and lentil batter. 
The dosa are gluten-free and dairy-free, with a sturdy yet soft texture that’s great for holding a hearty homemade potato filling.</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./dosa (1).jpg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>price</span>
                </span>
                <span className="primaryText">name</span>
                <span className="secondaryText">detail</span>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flexColStart r-card">
                <img src="./chhola (1).jpg" alt="home" />

                <span className="secondaryText r-price">
                  <span style={{ color: "orange" }}>$</span>
                  <span>price</span>
                </span>
                <span className="primaryText">Chole Bhature</span>
                <span className="secondaryText">Chole bhature is a food dish popular in the Northern areas of the Indian subcontinent. It is a combination of chana masala (spicy white chickpeas) 
                and bhatura/puri, a deep-fried bread made from maida.</span>
              </div>
            </SwiperSlide>
         
         
         
        </Swiper>
      </div>
    </div>
  );
};

export default Residencies;

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <div className="flexCenter r-buttons">
      <button onClick={() => swiper.slidePrev()} className="r-prevButton">
        &lt;
      </button>
      <button onClick={() => swiper.slideNext()} className="r-nextButton">
        &gt;
      </button>
    </div>
  );
};